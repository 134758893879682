import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Farm from './components/Farm/Farm';
import Tasks from './components/Tasks/Tasks';
import Boost from './components/Boost/Boost';
import Frens from './components/Frens/Frens';
import Navigation from './components/Navigation/Navigation';
import SplashScreen from './components/SplashScreen/SplashScreen';
import DesktopScreen from './components/DesktopScreen/DesktopScreen';
import { TokenProvider, useToken } from './contexts/TokenContext';
import { ToastContainer } from 'react-toastify';
import { useAxios } from './hooks/useAxios';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useDailyLoginBonus } from './hooks/useDailyLoginBonus';
import DailyLoginBonus from './components/DailyLoginBonus/DailyLoginBonus';
import { UserPointsProvider } from './contexts/UserPointsContext';

// Import images for preloading
import backgroundImage from './assets/images/background.png';
import tasksIcon from './assets/images/tasks.png';
import farmIcon from './assets/images/farm.png';
import boostIcon from './assets/images/boost.png';
import frensIcon from './assets/images/frens.png';
import lowResSpecialGameBackground from './assets/images/low-res-special-game-background.png';
import highResSpecialGameBackground from './assets/images/special-game-background.png';
import chimpImage from './assets/images/chimp-medium.png';
import tokenClickImage from './assets/images/taptap.png';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const AppContent: React.FC = () => {
    const [isSplashVisible, setIsSplashVisible] = useState(true);
    const [isDesktop, setIsDesktop] = useState(false);
    const [assetsLoaded, setAssetsLoaded] = useState(false);
    const { token, setToken } = useToken();
    const query = useQuery();
    const { showLoginBonus, loginStreak, closeLoginBonus } = useDailyLoginBonus();
    const [showDebug, setShowDebug] = useState(false);
    const axiosInstance = useAxios();

    const toggleDebug = () => setShowDebug(!showDebug);

    const saveParamToLocalStorage = (key: string) => {
        const value = query.get(key);
        if (value) {
            localStorage.setItem(key, value);
        }
    };

    const preloadImages = () => {
        const images = [
            backgroundImage,
            tasksIcon,
            farmIcon,
            boostIcon,
            frensIcon,
            lowResSpecialGameBackground,
            highResSpecialGameBackground,
            chimpImage,
            tokenClickImage,
        ];

        let loadedCount = 0;

        const preloadImage = (src: string) => {
            return new Promise<void>((resolve) => {
                const img = new Image();
                img.onload = () => {
                    loadedCount++;
                    if (loadedCount === images.length) {
                        setAssetsLoaded(true);
                    }
                    resolve();
                };
                img.onerror = () => {
                    console.error(`Failed to load image: ${src}`);
                    resolve();
                };
                img.src = src;
            });
        };

        Promise.all(images.map(preloadImage)).then(() => {
            setAssetsLoaded(true);
        });
    };

    useEffect(() => {
        const initializeApp = async () => {
            const queryToken = query.get('token');
            if (queryToken) {
                localStorage.setItem('chimpToken', queryToken);
                setToken(queryToken);

                ['uid', 'username', 'first_name', 'last_name', 'referrer_uid', 'is_premium'].forEach(saveParamToLocalStorage);

                const userData = {
                    uid: query.get('uid') || '',
                    username: query.get('username') || '',
                    first_name: query.get('first_name') || '',
                    last_name: query.get('last_name') || '',
                    referrer_uid: query.get('referrer_uid') || '',
                    is_premium: query.get('is_premium') === 'True'
                };

                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${queryToken}`;

                try {
                    const response = await axiosInstance.post('/user', userData);
                    console.log('User data sent successfully:', response.data);
                } catch (error) {
                    console.error('Error sending user data:', error);
                }
            } else {
                const storedToken = localStorage.getItem('chimpToken');
                if (storedToken) {
                    setToken(storedToken);
                }
            }

            preloadImages();
        };

        void initializeApp();
    }, [query, setToken, axiosInstance]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (assetsLoaded) {
                setIsSplashVisible(false);
            }
        }, 3000);
        return () => clearTimeout(timer);
    }, [assetsLoaded]);

    useEffect(() => {
        const telegramWebApp = window.Telegram?.WebApp;

        if (telegramWebApp) {
            telegramWebApp.expand();
            telegramWebApp.disableVerticalSwipes();

            const isDebug = !!process.env.REACT_APP_IS_DEBUG;
            const notMobilePlatform = telegramWebApp.platform !== 'ios' && telegramWebApp.platform !== 'android';

            if (!isDebug && notMobilePlatform) {
                setIsDesktop(true);
            }
        }
    }, []);

    if (isDesktop) {
        return <DesktopScreen />;
    }

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isSplashVisible || !assetsLoaded ? (
                <SplashScreen />
            ) : (
                <>
                    {showLoginBonus && (
                        <DailyLoginBonus streak={loginStreak} onClose={closeLoginBonus} />
                    )}
                    <Routes>
                        <Route path="/tasks" element={<Tasks />} />
                        <Route path="/farm" element={<Farm />} />
                        <Route path="/boost" element={<Boost />} />
                        <Route path="/frens" element={<Frens />} />
                        <Route path="/" element={<Farm />} />
                    </Routes>
                    <Navigation />
                </>
            )}
        </div>
    );
};

const App: React.FC = () => {
    return (
        <TokenProvider>
            <UserPointsProvider>
                <Router>
                    <AppContent />
                </Router>
            </UserPointsProvider>
        </TokenProvider>
    );
};

export default App;
