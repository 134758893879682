import React from 'react';
import './DailyLoginBonus.scss';
import dailyDone from '../../assets/images/daily-done.png';
import dailyAvailable from '../../assets/images/daily-available.png';
import dailyUnavailable from '../../assets/images/daily-unavailable.png';
import chimpSmall from '../../assets/images/chimp-small.png';

interface DailyLoginBonusProps {
    streak: number;
    onClose: () => void;
}

const bonusAmounts = [10000, 25000, 50000, 100000, 150000];

const DailyLoginBonus: React.FC<DailyLoginBonusProps> = ({ streak, onClose }) => {
    const handleClaimBonus = () => {
        onClose();
    };

    const formatAmount = (amount: number) => {
        return amount >= 1000 ? `${amount / 1000}K` : amount.toString();
    };

    const getIconSource = (index: number) => {
        if (index < streak - 1) return dailyDone;
        if (index === streak - 1) return dailyAvailable;
        return dailyUnavailable;
    };

    return (
        <div className="daily-login-bonus-overlay">
            <div className="daily-login-bonus-modal">
                <h2>DAILY LOGIN BONUS</h2>
                <div className="streak-container">
                    {bonusAmounts.map((amount, index) => (
                        <div key={index} className="streak-item">
                            <img
                                src={getIconSource(index)}
                                alt={`Day ${index + 1}`}
                                className="streak-icon"
                            />
                            <div className="bonus-points">
                                <img src={chimpSmall} alt="Chimp coin" className="coin-icon" />
                                <span className="points-text">+{formatAmount(amount)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <button onClick={handleClaimBonus}>Claim Bonus</button>
            </div>
        </div>
    );
};

export default DailyLoginBonus;
